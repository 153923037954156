.carousel-control-prev {
  left: -55px;
  width: auto;
}
.carousel-control-next {
  right: -55px;
  width: auto;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  border-radius: 50%;
  background-size: 20px;
  background-repeat: no-repeat;
  display: flex;
  align-content: center;
  justify-content: center;
  background-position: center;
  width: 40px;
  height: 40px;
  background-color: #3d4cd2;
}

.carousel-indicators {
  bottom: -70px;
}

.btn-slider {
  margin-top: 60px;
}

.slider-content-box figure img {
  height: 360px;
  width: auto;
}

.title-lable {
  margin-bottom: 0;
}

.manage-groups-list {
  padding-left: 0;
}

body {
  overflow: visible !important;
}

.profile-list li {
  white-space: normal;
  word-wrap: anywhere;
  align-items: flex-start;
}

.nav-sidebar-inner {
  overflow: auto;
}

.table-box p {
  margin-bottom: 0;
}

/* Edit Comment  */
.post-comment-list li {
  padding: 10px 0;
  border-bottom: 1px solid #e4e4e4;
}

.post-comment-list li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.post-comment-list li figcaption {
  flex: 1;
}
.post-comment-list .post-comment-list-L img {
  margin: 0;
  width: 42px;
  height: 42px;
  border-radius: 50px;
}

.post-input {
  position: relative;
}
.post-input .form-control {
  padding-right: 70px;
}
.post-input .form-action {
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  align-items: center;
}
.form-action button {
  border: none;
  background: none;
  font-size: 22px;
  margin-right: 5px;
}
.form-action button:last-child {
  margin-right: 0;
}
.form-action .sendBtn {
  color: #3d4cd2;
}
.form-action .crossBtn {
  color: #000;
  opacity: 0.5;
}
.form-action button:disabled{
  color: #e9e9eb
}

/* Receiver */

.receiver-group {
  height: auto;
}

.radio-box input {
  opacity: 1 !important;
}

/* Modal */
.avatarimage {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
.event-title {
  color: #000 !important;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

/* Dev */

.selected-text {
  color: #000 !important;
  border-color: #3d4cd2 !important;
}

.error-input {
  border: 1px solid #ce4a4a !important;
}

.text-danger {
  margin-bottom: 0 !important;
  color: #ce4a4a !important;
}

/* all-post */
.all-posts {
  padding-left: 45px;
}
.post-description {
  background: transparent;
  border: none;
  color: #3d4cd2;
}

/* react-input-tag */
.rti--tag{
  background-color: rgba(61,76,210,.11) !important;
  color: #000 !important; 
}
.rti--container{
  --rti-main:  #3d4cd2 !important;
  --rti-tag-remove: "#000000",
}
.rti--container:focus-within{
  box-shadow: none;
}

/* group and member list on post */
.all-group-L{
  max-height: 500px;
  overflow: auto;
}
#myTabContent .rti--input{width: 100%;}
.contact-info a{word-break: break-all;}
.pagination{flex-wrap: wrap;}
.pagination li{margin-bottom: 5px;}
.campaign-report-info{flex-wrap: wrap;}
.campaign-report-info .d-flex.flex-column{padding: 5px;}
.terms-content p,.terms-content span,.terms-content li{white-space:pre-line !important;}
.notification-dismiss{font-size: 12px; text-align: right; color: #374cce;}

.event-popup-box{text-align: left;}
.event-popup-box h1{font-size: 18px; color:#000000; font-weight: 800; text-transform: capitalize; margin-bottom: 10px;}
.event-popup-box p{font-size: 15px; color: #000000; margin-bottom: 10px;}

.popup-info ul li{display: flex; margin-bottom: 10px; align-items: center;}
.popup-info ul li i{color: #3D4CD2; font-size: 22px;}
.popup-info ul li span{font-size: 14px; color: #000000; padding-left: 10px; padding-top: 4px;}
.popup-info ul li:last-child{margin-bottom: 0;}
body .swal2-title{font-size: 28px; color:#000000; font-weight: 800;}
body .swal2-html-container{overflow: inherit;}
.rbc-time-view{background: #fff;}
body .rbc-event.rbc-selected, body .rbc-day-slot .rbc-selected.rbc-background-event{background:#374cce;}
.rbc-agenda-view{background: #fff;}
body .rti--input{width: 100%;}
.calender_box{
  background: white;
    padding: 46px;
}


.rbc-off-range-bg {
  background: #fff;
}

.tutorial_section{
  padding: 30px;
}

.attachment img{
  width: 50%;
}

mark{
  background-color: #374cce;
  color: white;
  border-radius: 8px;
}


.syncButton button{
  border: 1px solid grey;
  padding: 10px 31px;
  color: #423f3f;
  border-radius: 0px;
  background: white;
  width: 221px;
}


.react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker__input-container{width: 100%;}
.react-datepicker-wrapper{width: 100%;}

.react-datepicker-popper{
  z-index: 100 !important;
}

.remindButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.remindButton button{
  width: 200px;
}


